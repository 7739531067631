import React from 'react'

export default function TermsAndConditions() {
    return (
        <div className='tc_cms_pages_wrapper  py-5 animate__animated animate__fadeIn'>

            <div className="container">

                <div className='main'>
                    <h1><span>Terms and Conditions</span></h1>

                    <h3><b>Applicable of the Arrangement:</b></h3>

                    <p>The user agreement includes the terms and conditions for <i> <u>https://www.navchr.com</u></i> a unit of Navchr Online with its listed office at “navchr Online, Plot No-10, Baniya ka Bagh, Jaipur Bye Paas, Alwar, Rajasthan-301001 to provide facilities to the person the user intending to buying or inquiring for any products and/ services of navchr online by using <i><u> www.navchr.com</u></i> websites or using any other client interface networks of <i> <u>www.navchr.com/ www.navchr.com</u></i> which includes its salespersons, booking offices, customer care service ads, info movements etc.</p>
                    <p>If at any time you do not agree with any part of these terms and conditions, <b> YOU MUST DISMISS USE OF THIS SITE</b>.</p>

                    <h3>Users duty of awareness of this contract :</h3>

                    <p>The users availing facilities from navchr online shall be thought to have read, understood and expressly accepted the terms and conditions of this agreement, which shall govern the desired deal or provision of such services by www.navchr.com for all purposes, and shall be binding on the users. All rights and duties of the users www.navchr.com with respect to any services to be provided by navchr online shall be restricted to the scope of this agreement.</p>
                    <p>Navchr Online reserves the right, in its sole discretion, to terminate the access to the www.navchr.com website or its other sales channels and the related services or any portion thereof at any time, without notice, for general maintenance or any reason whatsoever.</p>
                    <p>Certain products or services may be provided by third-party suppliers. In addition to this agreement, there are certain terms of service explicit to the services products provided by www.navchr.com like the airline’s tickets etc. Such terms of service will be provided by navchr online which shall be deemed to be a part of this agreement and in the event of a conflict between such terms of service and this agreement, the terms of this agreement shall succeed. The users shall be required to read and accept the relevant terms of service for the service availed by the users.</p>
                    <p>Also, the service provider itself may provide terms and rules that govern particular features, offers or the operating rules and policies applicable to each Service. The user shall be responsible for ensuring compliance with the terms and guidelines or operating rules and policies of the service provider with whom the User elects to deal, including terms and conditions set forth in a service Providers fare rules, contract of carriage or other rules.</p>
                    <p>Navchr Online services are offered to the users trained on receipt without change of all the terms and conditions and notices contained in this agreement and the terms of service, as may be applicable from time to time. For the removal of worries, it is clarified that availing of the services by the users creates an salutation and receipt by the users of this agreement and the terms of service. If the users do not agree with any part of such terms and conditions and notices, the users must not avail navchr online services.</p>
                    <p>In the event that any of the terms, conditions, and notices contained herein conflict with the additional terms or other terms and guidelines contained within any other navchr online document, then these terms shall control.</p>

                    <h3>Third party acct info :</h3>

                    <p>By using the account access service in <i> www.navchr.com/ www.navchr.com</i> websites, the user authorizes navchr online and its agents to access third party sites, counting that of banks and other payment gateways, designated by them or on their behalf for retrieving requested info.</p>
                    <p>While registering, the users will choose a password and is responsible for keeping the privacy of the password and the account.</p>
                    <p>The users are fully responsible for all activities that occur while using their password or account. It is the duty of the users to notify navchr online closely in writing of any unofficial use of their password or account or any other breach of security. Navchr Online will not be liable for any loss that may be incurred by the users as a result of illegal use of his password or account, either with or without his information. The users shall not use anyone else password at any time.</p>

                    <h3>Charges of online pay :</h3>

                    <p>Navchr Online reserves the right to charge listing fees for certain listings, as well as business fees based on certain completed transactions using the services. Navchr Online further reserves the right to alter any and all fees from time to time, without notice.</p>
                    <p>The users shall be completely responsible for all charges, fees, duties, taxes, and charges arising out of the use of the services.</p>
                    <p>In case, there is a short charging by navchr online for listing, services or transaction fees or any other fee or service because of any technical or other reason, it reserves the right to deduct/charge/claim the balance subsequent to the transaction at its own discretion.</p>
                    <p>Any increase in the price charged by the company on account of change in the rate of taxes or imposition of new taxes by Government shall have to be borne by the customer.</p>
                    <p>In the rare possibilities of the reservation not getting confirmed for any reason whatsoever, we will process the refund and intimate you of the same. Navchr Online is not under any responsibility to make another booking in lieu of or to replace the unconfirmed one. All subsequent further bookings will be treated as new dealings with no reference to the earlier unverified booking.</p>
                    <p>The users shall request navchr online for any refunds against the unutilized or no show air or hotel booking for any reason within 60 days from the date of departure for the air ticket booking. Any applicable refunds would accordingly be processed as per the defined policies of Airlines, navchr online as the case may be. No refund would be payable for any requests made after the expiry of 60 days as above and all unclaimed amounts for such unutilized or no show air booking shall be deemed to have been forfeited.</p>

                    <h3>Concealment :</h3>

                    <p>Any info which is specifically mentioned by Navchr Online as personal shall be maintained privately by the users and shall not be disclosed unless as required by law or to serve the purpose of this agreement and the duties of both the parties therein.</p>

                    <h3>Custom of Mob No of User by Navchr Online :</h3>

                    <p>Navchr Online may send reservation approval, route info, cancellation, payment approval, refund status, timetable change or any such other info relevant for the deal, via SMS or by voice call on the contact number given by the users at the time of booking; Navchr Online may also contact the users by voice call, SMS or email in case the users could not or has not concluded the booking, for any reason what so ever, to know the preference of the user for concluding the booking and also to help the users for the same. The users hereby absolutely consents that such communications via SMS and/ or voice call by navchr online is upon the request and approval of the users transactional and not an unsolicited commercial communication as per the guidelines of Telecom Regulation Authority of India (TRAI) in compliance with the relevant guidelines of TRAI or such other authority in India and abroad. The users will indemnify Navchr Online against all types of losses and damages incurred by Navchr Online due to any action taken by TRAI, Access Providers (as per TRAI regulations) or any other authority due to any erroneous complaint raised by the users on Navchr Online with respect to the intimations mentioned above or due to a wrong number or email id being provided by the users for any reason whatever.</p>

                    <h3>The Duty of the Users :</h3>

                    <p>Navchr Online is responsible only for the dealings that are done by the users through navchr Online. Navchr Online will not be responsible for showing, editing or otherwise monitoring dealings, including whether the operation is legal and valid as per the laws of the land of the all navchr online users.</p>
                    <p>All navchr online users authorizations that they will take by all such additional events and guidelines, as modified from time to time, in connection with the use of the services. The users further warrant that they will comply with all valid laws and guidelines regarding the uses of the services with respect to the authority concerned for each deal.</p>
                    <p>The all navchr online users represent and confirm that the users is of legal age to enter into a required contract and is not a person barred from availing the facilities under the plans of India or other valid directions.</p>

                    <h3>Forces main Condition :</h3>

                    <p>All navchr online users approves that there can be extraordinary conditions where the facility workers like the airlines particular transference benefactors or concerns may be unable to honour the complete reservations due to several reasons like climatic situations, labour unrest, insolvency, business exigencies, government results, operative and technical issues, route and flight cancellations etc. If navchr Online is conversant in advance of such conditions where dishonour of reservations may happen, it will make its best efforts to deliver a like other to its clients or refund the reservation amount after practical service charges, if supported and returned by that particular package operators. The users approve that navchr online being an agent for smoothing the reservation services shall not be responsible for any such situations and the users have to contact that service provider directly for any further resolves and refunds.</p>
                    <p>The users approve that in conditions due to any practical or other letdowns in navchr online, services committed previous may not be providing or may include considerable adjustment. In such cases, navchr online shall repayment the entire amount received from the customer for availing such facilities minus the appropriate cancellation, refund or other charges, which shall completely discharge any and all liabilities of navchr online against such no ¬provision of services or absences. Other liabilities, if any, shall be borne by the all users.</p>
                    <p>Navchr online shall not be responsible for delays or failures in presentation or no presentation in whole or in part of its duties due to any causes that are not due to its acts or oversights and are beyond its reasonable control, such as acts of God, fire, strikes, restriction, acts of government, acts of bombing or other comparable causes, problems at all airline’s transporters end. In such an event, the all user artificial will be promptly given notice as the condition permits.</p>
                    <p>Without partiality to whatsoever is stated above, the maximum obligation on part of navchr online rising under any conditions, in deference of any facilities offered on the site, shall be limited to the refund of total amount received from the customer for availing the facilities less any cancellation, refund or others charges, as may be applicable. In no case, the obligation shall include any loss, damage or extra outlay whatsoever beyond the amount charged by navchr online for its facilities.</p>
                    <p>In no incident shall navchr online and its dealers be liable for any direct, indirect, punitive, incidental, special, important damages or any damages whatsoever including, without restriction, damages for loss of use, data or profits and arising out of or in any way linked with the use or performance of the navchr online website or any other channel. Neither shall navchr online be responsible for the delay or inability to use the navchr online sites or connected services, the facility of or failure to deliver services, or for any info, products, services and connected graphics obtained through the navchr online website, then arising out of the use of the navchr online website whether based on agreement, offense, disregard, strict obligation or else.</p>
                    <p>Navchr online is not answerable for any errors, lapses or representations on any of its pages or on any links or on any of the linked website pages.</p>

                    <h3>Severability :</h3>

                    <p>If any providing of this arrangement is resolute to be invalid or unenforceable in whole or in part, such inaccuracy or unenforceability shall attach only to such facility or part of such delivery and the outstanding part of such facility and all other provisions of this contract shall continue to be in full force and effect.</p>

                    <h3>Titles :</h3>

                    <p>The titles and subheadings here are involved for convenience and identification only and are not intended to define, interpret, define or limit the scope, degree or intent of this arrangement, terms and conditions, pictures, or the right to use this website by the user confined herein or any other section or pages of navchr online websites or its companion websites or any provision hereof in any custom whatever.</p>
                    <p>In the event that any of the terms and conditions, notices contained herein conflict with the additional terms, other terms and rules contained within any particular navchr online website, then these terms shall control.</p>

                    <h3>Connection :</h3>
                    <p>None of the provisions of any agreement, terms and conditions, notices, or the right to use this website by the user contained herein any other unit or sheets of navchr online websites or its companion sites, shall be deemed to establish a firm between the users and navchr online and no party shall have any auth to bind or shall be deemed to be the mediator of the other in any mode.</p>

                    <h3>Info Update :</h3>

                    <p>All navchr online users allows that navchr online provides services with sensible industry and care. It endeavours its best to ensure that the all users do not look any inconvenience. However, sometimes, the info, software, products, and facilities included in or available through the navchr online websites or extra sales networks and ad materials may include inaccuracies or typographical faults which will be nearly adjusted as soon as navchr online notices them. Changes are may be sometimes made and added to the info providing such. Navchr Online may make progresses changes in the navchr online websites at any time without any notice to the User. Any instruction received except through an legal representative of navchr online through the navchr online websites should not be relied upon for any decisions.</p>

                    <h3>Safe and secure your all Information :</h3>

                    <p>The all navchr online users knows and approves that any material data taken or otherwise obtained through the use of the service is done entirely at their own discretion and risk and they will be solely responsible for any injury to their computer systems or loss of data that results from the download of such material data.</p>
                    <p>Yet, navchr online will always make its best endeavours to ensure that the content on its websites or other info networks is free of any worm or such other malware.</p>

                    <h3>Advice from customer to Navchr Online :</h3>

                    <p>All users are alert that navchr online delivers all airlines transportable facilities would like to learn about them, to improve his / her transportable knowledge. The users hereby explicitly auth navchr online to connection the users with offers on various services offered by it through direct email, mobile call, SMS services and WhatsApp chart or follow our other social medium, time to time. In case that the customer chooses not to be contacted, he /she shall write to navchr online for specific exclusion at support@navchr.com. The customers are advised to read and understand the policy of navchr online on its website in accordance with which navchr online contacts, solicits the user or shares the users information.</p>

                    <h3>Private rights :</h3>

                    <p>Navchr Online may provide the users with contents such as sound, pictures, graphics, video and other material contained in patron ads or info. This material may be protected by patents, trademarks, or other intellectual property rights and rules.</p>
                    <p>The users may use this material only as clearly auth by navchr online and shall not copy, transmit or create derivative works of such material without express auth.</p>
                    <p>The users acknowledge and agrees that he/she shall not upload post, copy, distribute any content on or through the Services that is protected by copyright or other registered rights of a third party, without gaining the written approval of the owner of such right.</p>
                    <p>Any copyrighted or other proprietary content distributed with the consent of the owner must contain the appropriate copyright or other proprietary rights notice. The unauth submission or supply of copyrighted or other proprietary content is illegal and could subject the users to personal charge or illegal action.</p>

                    <h3>Use of Private and Noncommercial :</h3>

                    <p>Unless otherwise specified, the navchr online services are for the users personal and non-commercial use. The users may not adjust, copy, allocate, diffuse, display, perform, replicate, print, certificate, create derived works from, transfer, or sell any info, software, products or facilities got from the navchr online website without the express written approval from navchr online.</p>

                    <h3>Security :</h3>

                    <p>All navchr online users agrees to insure, defend and hold harmless navchr online, their websites and their individual legal replacements and assigns from and against any and all losses, charges, claims, damages, costs and expenses (including reasonable legal fees and expenditures in construction therewith and interest chargeable thereon) asserted against or incurred by navchr online and its affiliates, partner websites and their respective lawful successors and assigns that arise out of, result from, or may be payable by virtue of, any breach or no -performance of any representation, warranty, covenant or agreement made or obligation to be performed by the user pursuant to this agreement.</p>

                    <h3>Specific to Junk :</h3>

                    <p>Navchr Online at its sole discretion investments the right to not to accept any customer order without assigning any reason thereof. Any contract to provide any service by navchr online is not complete until full money towards the service is received from the customer and accepted by navchr online.</p>
                    <p>Without prejudice to the other remedies available to navchr online under this agreement, the terms of service or under applicable rule, navchr online may limit the users activity, end the users listing, warn other users of the user's actions, directly temporarily/indefinitely overhang, terminate the users registration and refuse to provide the user with access to the website if.</p>
                    <p>The users is in breach of this agreement, the terms of facility and documents it incorporates by reference.</p>
                    <ul>
                        <li>Navchr online is unable to verify or authenticate any information provided by the user.</li>
                        <li>Navchr online believes that the users actions may invade on any third party rights or breach any applicable law or otherwise result in any liability for the users of the website navchr online.</li>
                    </ul>
                    <p>Navchr online may at any time in its only choice reinstate postponed users. Once the user has been forever postponed the user shall not register or attempt to register with navchr online or use the website in any manner whatsoever until such time that the user is returned by navchr online.</p>
                    <p>Nevertheless the previous, if the user breaches this agreement, the terms of facility or the documents it incorporates by reference, navchr online reserves the right to recover any amounts due and owing by the user to navchr online the service provider and to take strict lawful action as navchr online deems necessary.</p>

                    <h3>Cancellation Right by Navchr Online in case of invalid info from the all users :</h3>

                    <p>All navchr online users clearly undertakes to provide to Navchr online only correct and valid info while inviting for any services under this agreement, and not to make any distortion of facts at all. Any default on part of the user would vitiate this agreement and shall disentitle the user from availing the services from navchr online.</p>
                    <p>In case navchr online realizes or has reasons to believe at any time during or after receiving a request for services from the user that the request for services is either illegal or the info providing by the user or any of them is not correct or that any fact has been altered by him, navchr online in its sole decision shall have the clear right to take any steps against the users, including cancellation of the reservations. without any prior intimation to the user. In such an event, navchr online shall not be responsible or liable for any loss or damage that may be caused to the user or any of them as a importance of such cancellation of reservation/ services.</p>
                    <p>The user clearly insures navchr online of any such claim or liability and shall not hold navchr online responsible for any loss or damage arising out of events taken by navchr online for protection its own interest and that of its honest customers. This would also include navchr online rejecting/cancelling any bookings on account of assumed fraud transactions.</p>

                    <h3>Clarification of number and gender :</h3>

                    <p>The terms and conditions here shall apply similarly to both the singular and plural form of the terms defined. Whenever the context may require, any pronoun shall include the consistent masculine, female and neuter form. The words ‘include’, ‘includes’ and ‘including’ shall be deemed to be followed by the phrase ‘without limit’. Unless the context otherwise requires, the terms "herein", "hereof", "hereto", 'hereunder" and words of similar import refer to this agreement as a whole.</p>

                    <h3>Terms of use Change :</h3>

                    <p>Navchr Online investments the right to change the terms and conditions, and notices under which the navchr online websites are offered, including but not limited to the charges. The all users is responsible for regularly studying these terms and conditions.</p>

                    <h3>Auth of Navchr Online :</h3>

                    <p>Navchr online hereby expressly disclaims any implied warranties credited by the laws of any dominion where it is functional its offices. Navchr online considers itself and intends to be subject to the authority only of the courts of Alwar, Rajasthan-301001, India. The arbitration proceedings shall be in the Hindi language. The Terms and Conditions shall be governed and construed in accordance with the laws of India.</p>

                    <h3>Responsible of user the agreement :</h3>

                    <p>The users expressly agrees that use of the services is at their sole risk. To the extent navchr online acts only as a booking agent on behalf of third-party service providers, it shall not have any liability whatsoever for any aspect of the standards of services provided by the service providers. In no conditions shall navchr online be liable for the services provided by the service provider. The services are provided on an "as is" and "as available" basis. Navchr Online may change the features or functionality of the services at any time, in their sole option, without notice. Navchr Online expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and non¬infringement. No advice or information, whether oral or written, which the user obtains from Navchr Online or through the services shall create any warranty not expressly made herein or in the terms and conditions of the services. If the user does not agree with any of the terms above, they are advised not to read the factual on any of the navchr online pages or otherwise use any of the contents, pages, info or any other factual providing by navchr online. The sole and exclusive remedy of the user in case of disagreement, in whole or in part, of the user agreement, is to discontinue using the services after notifying navchr online in writing.</p>

                    <h3>Visa Material :</h3>

                    <p>The visa rules mentioned on navchr online website are updated on best effort basis. Please refer to the respective countries official website to understand the complete and updated visa related requirements. In case of transit visa, please refer to the transit countries official website for complete and updated visa supplies. Navchr Online would not be liable for any visa related issues.</p>

                    <h3>Redressal Rule Protest :</h3>

                    <p>Navchr Online powerfully trusts in a sincere and clear approach to its customers & to ensure that, we have a strong grievance redressal mechanism in place. This instrument ensures that every complaint is handled in a expressive and effective way & within the stipulated timeline.</p>
                    <p>How to reach Navchr Online for service related issues :</p>
                    <ul>
                        <li>We request to reach out to our customer care team at <i> +<u>91 9336111631</u></i> or write to us at <i><u> support@navchr.com</u></i>. Please do provide your navchr online Trip Id. You will receive a response in few hours.</li>
                        <li>If the resolution provided doesn’t meet your expectation then within 5 days, please write to us at <i><u> support@navchr.com</u></i> along with your navchr online trip id.</li>
                        <li>If you are still not satisfied with the response (after a period of 7 working days from the date of receipt of Level 2 Grievances), please write to :</li>
                    </ul>
                    <p><b>Complaint Officer </b></p>
                    <p><b>Navchr Online <br />
                        Plot No 10, Baniya Ka Bagh, Jaipur Bye Pass <br />
                        Alwar, Rajasthan-301001</b>.
                    </p>
                </div>
            </div>

        </div>
    )
}
